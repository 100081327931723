import axios from "axios"

const API_URL = "https://bvlmain.net/api/"
// const API_URL = "http://localhost:8000/api/"

export const UserLogin = async (body) => {
  try {
    const { data } = await axios.post(`${API_URL}login/loginpasscode`, body)
    return data
  } catch (error) {
    console.log(error)
  }
}
